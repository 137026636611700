
import { defineComponent } from "vue";
import CheckboxSlide from "../inputs/CheckboxSlide.vue";
import NotificationPopup from "../notifications/NotificationPopup.vue";
import { WidgetI, HtmlEvent } from "../../interfaces/interfaces";
import ErrorIcon from "../icons/ErrorIcon.vue";
import { showDays, showVariant, IShowDay, IShowVariant } from "./constants";
import DropdownMenu from "../inputs/DropdownMenu.vue";
import { changeCase } from "../../helpers";
import { ElementE, MatrixDisplayDetailsE } from '../../enums'

interface ErrorI {
  availableText: string;
  unavailableText: string;
  [key: string]: string;
}

export default defineComponent({
  name: "DisplayTab",
  props: {
    widget_info: {
      type: Object,
    },
    widgets: {
      type: Object,
    },
  },
  emits: ["updateWidget"],
  watch: {
    widgets: function (widgets: WidgetI) {
      this.widget = widgets;
      if (this.widget.config.module == "matrix") {
        this.matrix = true;
        this.matrixView();
      } else {
        this.matrix = false;
      }

      if (this.widget.config.module == "search") {
        this.search = true;
      } else {
        this.search = false;
      }

      if (this.widget.config.module == "matrixSearch") {
        this.matrixSearch = true;
        this.matrixView();
      } else {
        this.matrixSearch = false;
      }

      if (
        this.widget.config.available_text != null &&
        this.widget.config.matrix_mode == "availableText"
      ) {
        this.showAviable = true;
      }

      if (this.widget.config.show_property_thumbnail) {
        this.showPropertyThumbnail = true;
      } else {
        this.showPropertyThumbnail = false;
      }

      if (this.widget.config?.matrix_on_mobile) {
        this.displayMatrixOnMobile = this.widget.config.matrix_on_mobile;
      }
    },
  },
  components: {
    CheckboxSlide,
    NotificationPopup,
    ErrorIcon,
    DropdownMenu,
  },
  data() {
    return {
      showPropertyThumbnail: true,
      isShowSavePopup: false,
      matrix_mode: MatrixDisplayDetailsE.availableText,
      search: true,
      matrixSearch: false,
      matrix: false,
      widget: {} as WidgetI,
      showMatrixView: false,
      showAviable: false,
      showDays: [] as IShowDay[],
      showVariant: [] as IShowVariant[],
      maxLength: 10,
      errors: {
        availableText: "",
        unavailableText: "",
      } as ErrorI,
      changeCase: changeCase,
      ElementE: ElementE,
      MatrixDisplayDetailsE,
      displayMatrixOnMobile: false,
    };
  },
  mounted() {
    this.showDays = showDays;
    this.showVariant = showVariant;
    this.showMatrixView = false;
    if (this.widget.config) {
      if (this.widget.config.show_property_thumbnail) {
        this.showPropertyThumbnail = this.widget.config.show_property_thumbnail;
      }
    }
  },
  methods: {
    validateField(fieldName: string, value: string) {
      if (!value) {
        this.errors[fieldName] = "The field should not be empty";
      } else if (value.length > this.maxLength) {
        this.errors[
          fieldName
        ] = `The length of field must be less than ${this.maxLength} characters`;
      } else {
        this.errors[fieldName] = "";
      }
    },

    showPropThumbnailChange(e: HtmlEvent) {
      this.widget.config.show_property_thumbnail = e.target.checked;
      this.showPropertyThumbnail = e.target.checked;
      this.updateWidget();
    },
    changeDisplayDays(days: string) {
      this.widget.config.display_days = parseInt(days);
      this.updateWidget();
    },
    updateWidget() {
      this.isShowSavePopup = true;
      this.$emit("updateWidget", this.widget);
    },
    changeBtn(e: HtmlEvent) {
      let checkBox = e.target.id;
      this.search = false;
      this.matrix = false;
      this.matrixSearch = false;

      if (checkBox == "search") {
        this.search = true;
        this.displayMatrixOnMobile = false;
        this.matrixViewHide();
      }
      if (checkBox == "matrix") {
        this.matrix = true;
        this.matrixView();
      }
      if (checkBox == "matrixSearch") {
        this.matrixSearch = true;
        this.displayMatrixOnMobile = false;
        this.matrixView();
      }

      this.widget.config.matrix_on_mobile = this.displayMatrixOnMobile;
      this.widget.config.module = checkBox;
      this.updateWidget();
    },
    showAviableField(id: string) {
      this.showAviable = id === 'availableText';
      this.widget.config.matrix_mode = id;
      this.updateWidget();
    },
    matrixView() {
      this.showMatrixView = true;
    },
    matrixViewHide() {
      this.showMatrixView = false;
    },
    enableDisplayMatrixOnMobile(value: boolean) {
      this.displayMatrixOnMobile = value;
      this.widget.config.matrix_on_mobile = this.displayMatrixOnMobile;
      this.updateWidget();
    },
  },
});
