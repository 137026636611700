<template>
  <div class="flex items-center">
    <label
      class="relative flex items-center"
      :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
    >
      <input
        type="checkbox"
        class="sr-only peer"
        v-model="active"
        :disabled="disabled"
      />
      <div :class="checkboxClasses" />
      <span
        class="ml-2"
        :class="[
          disabled ? 'text-action-disabled' : 'text-gray-900',
          size === 'xs' ? 'text-xs font-regular' : 'text-sm font-medium'
        ]"
      >
        {{ changeCase(label, ElementE.LABEL) }}
      </span>
    </label>

    <div v-if="tooltipTxt" class="relative ml-2">
      <QuestionMarkIcon
        @mouseover="showTooltipTxt = true"
        @mouseleave="showTooltipTxt = false"
      />
      <div
        v-show="showTooltipTxt"
        class="absolute w-72 p-4 rounded-lg bg-white z-10 shadow-secondary"
      >
        <p class="tooltip-content">
          {{ tooltipTxt }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMarkIcon from "../icons/QuestionMarkIcon.vue";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums';

export default {
  name: "CheckboxSlide",
  components: { QuestionMarkIcon },
  props: {
    label: { type: String, required: true },
    isChecked: { type: Boolean, required: false, default: false },
    info: { type: String, default: "" },
    tooltipTxt: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: "default" },
  },
  emits: ['back', 'update:isChecked'],
  data() {
    return {
      showTooltipTxt: false,
      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
  computed: {
    checkboxClasses() {
      return {
        'checkbox-slide': true,
        'checkbox-xs': this.size === 'xs',
        'checkbox-default': this.size === 'default',
      };
    },
    active: {
      get() {
        return this.isChecked;
      },
      set(value) {
        this.$emit('update:isChecked', value);
      },
    },
  },
};
</script>

<style scoped>
.checkbox-slide {
  @apply rounded-[100px] bg-neutral-50 after:content-[''] after:absolute after:top-0.5 after:left-0.5
    after:rounded-full after:border after:border-neutral-50 after:bg-white after:transition-all
    peer-checked:bg-primary-main peer-checked:after:translate-x-[18px] peer-checked:after:border-white peer-disabled:bg-neutral-25;
}

.checkbox-xs {
  @apply w-[34px] h-4 after:w-3 after:h-3;
}

.checkbox-default {
  @apply w-[38px] h-5 after:w-4 after:h-4;
}

.checkbox-label {
  @apply pl-2;
}
</style>
